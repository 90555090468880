<template>
  <!--预约订单详情-->
  <div class="yuYueOrderDetails">
    <!-- 内容 -->
    <div class="content">
      <h2 class="title">
        <span class="title_icon"></span>
        订单信息
      </h2>
      <el-form label-position="right" style="padding-left:100px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="车牌号:">
              <span class="spanA">{{formInline.plateNumber}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="车牌颜色:">
              <span class="spanA">{{formInline.plateColor}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="订单状态:">
              <span class="spanA">{{getStateName(formInline.orderState)}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="手机号:">
          <span class="spanA">{{formInline.mobile}}</span>
        </el-form-item>
        <el-form-item label="停车场名称：">
          <span class="spanA">{{formInline.parkName}}</span>
        </el-form-item>
        <el-form-item label="预约到场时间：">
          <span class="spanA">{{formInline.appointmentStartTime}}</span>
        </el-form-item>
        <el-row>
          <el-col :span="8">
            <el-form-item label="车位最晚保留到:">
              <span class="spanA">{{formInline.appointmentEndTime}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="formInline.orderState > 1">
            <el-form-item label="取消时间:">
              <span class="spanA">{{formInline.actualFinish!=undefined?formInline.actualFinish:""}}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <h2 class="title" v-if="!formInline.checkDepositMoney">
        <span class="title_icon"></span>
        支付信息
      </h2>
      <el-form
        label-position="right"
        style="padding-left:100px"
        v-if="!formInline.checkDepositMoney"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="实付金额:" class="parknum">
              <span class="spanA">{{(formInline.payMoney / 100).toFixed(2)}}元</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="支付方式:" class="parknum">
              <span class="spanA">{{formInline.payTypeName}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="支付时间:" prop="yajin">
              <span class="spanA">{{formInline.payTime}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="第三方流水号:">
              <span class="spanA">{{formInline.payOutTradeNo}}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <h2 class="title" v-if="!formInline.checkDepositMoney && formInline.orderState != '0'">
        <span class="title_icon"></span>
        退费信息
      </h2>
      <el-form
        label-position="right"
        style="padding-left:100px"
        v-if="!formInline.checkDepositMoney && formInline.orderState != '0'"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="退费金额:">
              <span class="spanA">{{formInline.refundMoney}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="退费渠道:">
              <span class="spanA">{{formInline.refundTypeName}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="退费时间:">
              <span class="spanA">{{formInline.refundTime}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="第三方流水号:">
              <span class="spanA">{{formInline.refundOutTradeCode}}</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="margin:0 auto;width:250px;margin-bottom:20px;">
        <el-button type="button" @click="$router.go(-1)">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
// import { dateFormat } from "@/common/js/public.js";
export default {
  name: "bookingOrderDetails",
  data() {
    return {
      formInline: {},
    };
  },
  created() {
    let parkAppointmentOrderId = this.$route.query.appointmentOrderId;

    this.inItData(parkAppointmentOrderId);
  },
  methods: {
    getStateName(state) {
      return [
        "进行中",
        "已完成",
        "超时自动取消",
        "用户手动取消",
        "用户放弃预约",
        "后台取消",
      ][state];
    },
    inItData(id) {
      this.$axios
        .get("/acb/2.0/parkAppointmentOrder/park/appointment/findById/" + id)
        .then((res) => {
          if (res.state == 0) {
            if (res.value.refundMoney == 0) {
                res.value.refundMoney = ""
            } else {
                res.value.refundMoney  = (res.value.refundMoney / 100).toFixed(2) + "元"
            }
            this.formInline = res.value;

            this.formInline.plateColor = res.value.plateNumber.slice(0, 1) + "色"
            this.formInline.plateNumber = res.value.plateNumber.slice(2)
          }
        });
    },
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.title {
  font-size: 14px;
}

.Wrapper {
  overflow: hidden;
}

.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;
  border: 1px solid #C0CCDA;
  border-radius: 4px;
  padding: 20px;

  .inputTip {
    margin-left: 20px;
    color: #999;
  }

  .oRelative {
    position: relative;

    span {
      position: absolute;
      right: -20px;
      top: 0;
    }
  }
}

.spanA {
  height: 40px;
  line-height: 40px;
  display: inline-block;
}
</style>
